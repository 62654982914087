import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import BigNumberJs from 'bignumber.js';

import StakingChart from './delegations/StakingChart';
import './ProfileOverview.scss';

import { AccountStakeQueryData } from 'common/hooks/useAccountStakeQuery';
import {
  Buffer,
  Container,
  DataGrid,
  Divider,
  FortEntry,
  Row,
  Subtitle,
  TextEntry,
  Title
} from 'forta-app/components/scan-node-pool/ScannerPoolInfoBox';
import useProvider from 'common/hooks/useProvider';
import FortaToken from 'forta-app/lib/contract-interactors-2/forta-token';
import { APP_FULL_DATE_FORMAT } from 'common/constants';
import { Network } from 'common/lib/networks';
import { currentENV } from 'common/config';
import useVault from 'common/hooks/useVault';
import ClaimedRewardsChart from './delegations/ClaimedRewardsChart';

interface OverviewProps {
  stakeQuery: AccountStakeQueryData;
  address: string;
}
export default function ProfileOverview(props: OverviewProps): JSX.Element {
  const { stakeQuery, address } = props;
  const { stake } = stakeQuery;

  const chainId =
    currentENV === 'production' ? Network.PolygonMainnet : Network.BaseSepolia;
  const provider = useProvider(chainId);
  const fortContract = useMemo(() => new FortaToken({ provider }), [provider]);

  const vault = useVault({
    account: address
  });

  const balanceOfQuery = useQuery({
    queryKey: ['getFortBalance', address],
    queryFn: async () => await fortContract.balanceOf(address)
  });

  const firstStakeTimestamp = useMemo(() => {
    if (!stake) return null;
    return stake.history.total[0]?.timestamp ?? null;
  }, [stake]);

  useEffect(() => {
    if (!stakeQuery.loading && !stakeQuery.refetching && stakeQuery.data) {
      stakeQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalStaked =
    stake?.totalStake && vault.userAssets
      ? new BigNumberJs(stake.totalStake.toString()).plus(vault.userAssets)
      : null;

  return (
    <div className="ProfileOverview">
      <section className="ProfileOverview__section">
        <Row>
          <Container>
            <Title>Positions</Title>
            <Divider />
            <DataGrid>
              <Subtitle>Active Since</Subtitle>
              <Subtitle>Wallet</Subtitle>
              <TextEntry>
                {firstStakeTimestamp
                  ? dayjs.unix(firstStakeTimestamp).format(APP_FULL_DATE_FORMAT)
                  : '—'}
              </TextEntry>
              {balanceOfQuery.isLoading ? (
                <TextEntry>
                  <Skeleton width={70} />
                </TextEntry>
              ) : (
                <FortEntry value={balanceOfQuery.data} />
              )}
              <Buffer />
              <Subtitle>Staking</Subtitle>
              <TextEntry></TextEntry>
              <TextEntry>FORT Vault</TextEntry>
              {!vault.userAssets ? (
                <TextEntry>
                  <Skeleton width={70} />
                </TextEntry>
              ) : (
                <FortEntry value={vault.userAssets.toString(10)} />
              )}
              <TextEntry>Detection Bots</TextEntry>
              <FortEntry value={stake?.totalBotsStake} />
              <TextEntry>Scanner Pools</TextEntry>
              <FortEntry value={stake?.totalPoolsStake} />
              <TextEntry>Delegations</TextEntry>
              <FortEntry value={stake?.totalDelegationsStake} />
              <TextEntry>Total Staked</TextEntry>
              {!totalStaked ? (
                <TextEntry>
                  <Skeleton width={70} />
                </TextEntry>
              ) : (
                <FortEntry value={totalStaked.toString(10)} />
              )}
            </DataGrid>
          </Container>
        </Row>
      </section>
      <StakingChart
        stake={stake}
        loading={stakeQuery.loading || stakeQuery.refetching}
        className="ProfileOverview__section"
      />
      <ClaimedRewardsChart
        stake={stake}
        loading={stakeQuery.loading || stakeQuery.refetching}
        className="ProfileOverview__section"
      />
    </div>
  );
}
